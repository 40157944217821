import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavBar from './navbar';
import TopBar from './topbar';
import {layoutStyles} from '../css/layouts';

function DashboardLayout({ children }) {
  const classes = layoutStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <div className={classes.dashboardWrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};
export default DashboardLayout;
