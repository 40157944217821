import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import {PLATFORM_NAME, PLATFORM_ALIAS, PLATFORM_VERSION} from "src/config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 80,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography component="p" variant="overline" color="secondary" align="center">
          {`${PLATFORM_NAME} v${PLATFORM_VERSION} (${PLATFORM_ALIAS})`} Features
        </Typography>
        <Box mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  01
                </Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    NIST Compliant
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {PLATFORM_NAME} complies with or exceeds the most recent NIST guidelines.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  02
                </Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Customizable
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    From work-flows to reporting, {PLATFORM_NAME} is fully customizable. With wizard driven customization you no longer need to be an IT professional to manage your data.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  03
                </Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Seamless Integration
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    {PLATFORM_NAME} works seamlessly with IMPACT products.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
