import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import {PLATFORM_NAME, PLATFORM_ALIAS, PLATFORM_DESCRIPTION, PLATFORM_VERSION} from "src/config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    position: 'relative',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Typography variant="overline" color="secondary">
                NEW RELEASE
              </Typography>
              <Typography variant="h1" color="textPrimary">
                {`${PLATFORM_NAME} v${PLATFORM_VERSION} (${PLATFORM_ALIAS})`}
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  A enterprise level, NIST compliant <b>{PLATFORM_DESCRIPTION}</b> built from the ground-up for
                  Electric, Water, and Gas Utilities. {PLATFORM_NAME} integrates seamlessly
                  with all IMPACT service applications, and is available as part of the IMPACT enterprise suite or
                  as a standalone solution.
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={4}>
                  <Grid item>
                    <Typography variant="h1" color="secondary">
                      Security
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      NIST Compliant
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="secondary">
                      Scale
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      1K - 10M+
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="secondary">
                      Scope
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Field Activities
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <div className={classes.image}>
                <img alt="Connected" src="images/branding/dagda_bkg.png" />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
