import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Search as SearchIcon,
  XCircle as XIcon
} from 'react-feather';
import axios from 'src/utils/axios';
import { PLATFORM_SEARCH_URL } from 'src/config';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  }
}));

// Search Results:
// {uid: 0, title: '', description: '', avatar: '', href: '', payload: any}

function Search({className}) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchOpen = () => {
    setOpen(true);
  };

  const handleSearchClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      // TODO: Redo This Search URL.
      const response = await axios.get(PLATFORM_SEARCH_URL);

      setResults(response.data.results);
    } catch (error) {
      enqueueSnackbar('Something went wrong', {variant: 'error'});
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleSearchOpen}>
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleSearchClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" color="textPrimary">
                Search
              </Typography>
              <IconButton onClick={handleSearchClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField fullWidth
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position="start">
                               <SvgIcon fontSize="small" color="action">
                                 <SearchIcon />
                               </SvgIcon>
                             </InputAdornment>
                           )
                         }}
                         onChange={(event) => setValue(event.target.value)}
                         placeholder="Search"
                         value={value}
                         variant="outlined"
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" onClick={handleSearch}>
                Search
              </Button>
            </Box>
            <Box mt={4}>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {results && results.map((result) => (
                    <Box mb={2}>
                      <Link variant="h4" color="textPrimary" component={RouterLink} to={`${result.href}`}>
                        {result.title}
                      </Link>
                      <Typography variant="body2" color="textPrimary">
                        {result.description}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}
Search.propTypes = {
  className: PropTypes.string
}
export default Search;
