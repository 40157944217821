import {useEffect} from 'react';
import {useLocation} from 'react-router';

function ScrollRebound() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}
export default ScrollRebound;
