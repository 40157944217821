import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { URL_ENC_FORM } from "src/constants";
import authService from "src/services/authService";
import securityStorage from 'src/storage/security';
import { authWithCredentials } from 'src/services/actions/authActions';

const useStyles = makeStyles((theme) => ({

}));

function SignInForm ({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        email: securityStorage.getAuthEmail(),
        password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(255).required('Email address is required'),
        password: Yup.string().min(6).max(255).required('Password is required')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await dispatch(authWithCredentials(values.email, values.password));
          onSubmitSuccess();
        } catch (error) {
          console.log(error);
          const message = (error && error.message) || 'An unknown error occurred';
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
        <form noValidate encType={URL_ENC_FORM} className={clsx(classes.root, className)} onSubmit={handleSubmit} {...rest}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box mt={2}>
            <Button color="secondary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
              Sign In
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

SignInForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};
SignInForm.defaultProps = {
  onSubmitSuccess: () => {}
};
export default SignInForm;
