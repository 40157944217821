import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles, Link
} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  copyright: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Box my={3}>
          <Divider />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Link className={classes.link} color="textSecondary" component={RouterLink} to="/legal/terms-conditions" underline="none" variant="caption">
              Terms of Service
            </Link>
            {' '}
            <Link className={classes.link} color="textSecondary" component={RouterLink} to="/legal/privacy-policy" underline="none" variant="caption">
              Privacy Policy
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flexDirection="row">
              <Typography className={classes.copyright} variant="caption" color="textSecondary">
                &copy; {new Date().getFullYear()} Lamerra, LLC. All rights reserved.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
