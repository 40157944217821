import React from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Typography,
  ButtonBase,
  makeStyles,
  fade
} from '@material-ui/core';
import Logo from './Logo';
import {PLATFORM_VERSION, PLATFORM_NAME} from '../config';

const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: theme.spacing(2)
  },
  title: {
    maxWidth: 180,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: theme.typography.fontWeightMedium
  },
  subTitle: {
    marginTop: -2,
    color: fade(theme.palette.common.white, 0.55)
  }
}));

function Branding() {
  const classes = useStyles();
  const history = useHistory();
  const {profile} = useSelector((state) => state.instance);

  const handleBrandingClick = () => {
    // TODO: Set the Company Profile route
    if (profile) {
      history.push('/home');
    } else {
      history.push('/home');
    }
  };

  return (
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleBrandingClick}>
        <Logo className={classes.logo} />
        <Box display="flex" alignItems="flex-start" justifyContent="center" flexDirection="column">
          {profile && profile.name && (
            <Typography variant="h6" color="inherit" className={classes.title}>
              {profile.name}
            </Typography>
          )}
          <Typography variant="caption" color="inherit" className={clsx(classes.title, classes.subTitle)}>
            {PLATFORM_NAME}
            {' v'}
            {PLATFORM_VERSION}
          </Typography>
        </Box>
      </Box>
  );
}
export default Branding;
