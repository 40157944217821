
// Reads the settings form the local storage.
// and returns the parsed settings object.
export function loadSettings () {
  let settings = null;
  try {
    const storedData = localStorage.getItem('settings');
    if (storedData) { settings = JSON.parse(storedData); }
  } catch (err) {
    console.log(err);
  }
  return settings;
}

// Saves the settings to local storage.
export function storeSettings (settings) {
  localStorage.setItem('settings', JSON.stringify(settings));
}
