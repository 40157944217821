/* eslint-disable no-param-reassign */
import produce from 'immer';
import {CMP_KEYS} from 'src/services/actions/actionKeys';

const initialState = {
  profile: {},
  addresses: [],
  contacts: [],
  privileges: {
    role: 0,
    title: 'Manager',
    access: [
      {
        appId: 0,
        rights: 0
      }
    ]
  }
};

// Manages instance profiles.
const instanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case CMP_KEYS.RESET: {
      return produce(state, (draft) => {
        draft.profile = {};
        draft.addresses = [];
        draft.contacts = [];
        draft.privileges = [];
      });
    }
    case CMP_KEYS.FUL_FAIL: {
      return produce(state, (draft) => {
        draft.profile = {};
        draft.addresses = [];
        draft.contacts = [];
        draft.privileges = [];
      });
    }
    case CMP_KEYS.FUL_OK: {
      const {profile, addresses, contacts, privileges} = action.payload;
      return produce(state, (draft) => {
        draft.profile = profile;
        draft.addresses = addresses;
        draft.contacts = contacts;
        draft.privileges = privileges;
      });
    }
    default: {
      return state;
    }
  }
}
export default instanceReducer;
