import React, {useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Link,
  Portal,
  Typography,
  makeStyles
} from '@material-ui/core';
import {PLATFORM_NAME} from '../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  }
}));

function CookieDialog() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    // TODO: Track Cookie Information.
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');
    if (!consent) {
      setOpen(true);
    }
  }, []);
  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography variant="body1" color="inherit">
          As part of our goal to deliver the best user experience possible, {PLATFORM_NAME} uses Cookies on our web
          platform. Read our
          {' '}
          <Link component={RouterLink} color="inherit" underline="always" to="/legal/privacy-policy">
            Privacy Policy
          </Link>
          .
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} variant="contained" className={classes.action}>
            I Agree
          </Button>
        </Box>
      </div>
    </Portal>
  );
}
export default CookieDialog;
