import React, { lazy, Suspense } from 'react';
import Page from 'src/components/Page';
import Footer from "./footer";
import {Box, Divider} from "@material-ui/core";
const Content = lazy(() => import('!babel-loader!mdx-loader!./content.mdx'));

function TermsOfServiceView() {
  return (
    <Page title="Terms & Conditions">
      <Suspense fallback={null}>
        <Content />
      </Suspense>
      <Box my={3}>
        <Divider />
      </Box>
      <Footer />
    </Page>
  );
}

export default TermsOfServiceView;
