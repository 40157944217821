import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {THEMES} from "../constants";
import { storeSettings } from '../storage/settings';

const defaultSettings = {
  responsiveFontSizes: true,
  theme: THEMES.DARK
};

const SettingsContext = createContext(defaultSettings);
export default SettingsContext;

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);
    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };
  return (
    <SettingsContext.Provider value={{settings: currentSettings, saveSettings: handleSaveSettings}}>
      {children}
    </SettingsContext.Provider>
  );
}
SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};


