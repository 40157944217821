import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles, Link
} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  copyright: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function Footer() {
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.root} maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Link className={classes.link} color="textSecondary" component={RouterLink} to="/legal/terms-conditions" underline="none" variant="caption">
              Terms of Service
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flexDirection="row">
              <Typography className={classes.copyright} variant="caption" color="textSecondary">
                &copy; {new Date().getFullYear()} Lamerra, LLC. All rights reserved.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer;
