import dataService from '../services/genericDataService';

// Manages Dashboard/Report data sources that require
// Generic Types of data either on a interval or AdHoc basis.
class DataSourceApi {

  // Initializes the DataSource API.
  constructor() {
    this["120000"] = [];
    this["300000"] = [];
    this["1000000"] = [];
    this.run = false;
  }

  // Processes the request using the
  // generic data service and returns
  // the results.
  async exeDataRequest (ds) {
    try {
      const params = ds.params();
      const data = await dataService.postDataRequest(ds.id, params);
      if (ds.callback) { ds.callback({data: data, error: null}); }
    } catch (error) {
      if (ds.callback) { ds.callback({error}); }
    }
  }

  // Sends a single request for a data resource
  // to the server and returns the results.
  requestData (id, params, callback) {
    this.exeDataRequest({id, params, callback}).catch(console.error);
  }

  // Processes the data sources
  // contained in the 2-minute run-loop.
  handleRunloop2 () {
    this["120000"].forEach((ds) => {
      this.exeDataRequest(ds).catch(console.error);
    });
  }

  // Processes the data sources
  // contained in the 5-minute run-loop.
  handleRunloop5 () {
    this["300000"].forEach((ds) => {
      this.exeDataRequest(ds).catch(console.error);
    });
  }

  // Processes the data sources
  // contained in the 10-minute run-loop.
  handleRunloop10 () {
    this["1000000"].forEach((ds) => {
      this.exeDataRequest(ds).catch(console.error);
    });
  }

  // Registers the datasource for the specified
  // id to the specified run-loop.
  //
  // The params is a function used to provide
  // current parameters for the resource being fetched.
  // The callback will return the results.
  //
  // parameters: [{name:value}]
  // callback: ({data: any, error: Error})
  register (id, runLoop, params, callback, exe) {
    this[runLoop].push({id, params, callback});
    if (exe) { this.exeDataRequest({id, params, callback}).catch(console.error); }
  }

  // Removes the datasource with the specified
  // id from all run-loops.
  unRegister (id, runLoop) {
    this[runLoop] = this[runLoop].filter((rl) => rl.id !== id);
  }

  // Starts the run-loops on their
  // initial timeout intervals.
  start () {
    this.run = true;
    let t = this;
    // 2-Minute
    this.id2 = setTimeout(function run () {
      t.handleRunloop2();
      if (t.run) {
        t.id2 = setTimeout(run, 120000);
      }
    }, 120000);

    // 5-Minute
    this.id5 = setTimeout(function run () {
      t.handleRunloop5();
      if (t.run) {
        t.id5 = setTimeout(run, 300000);
      }
    }, 300000);

    // 10-Minute
    this.id10 = setTimeout(function run () {
      t.handleRunloop10();
      if (t.run) {
        t.id10 = setTimeout(run, 1000000);
      }
    }, 1000000);
  }

  // Stops the run-loops by setting the 'run' status
  // to false and clearing the timeouts.
  stop () {
    this.run = false;
    if (this.id2) {
      clearTimeout(this.id2);
      this.id2 = null;
    }
    if (this.id5) {
      clearTimeout(this.id5);
      this.id5 = null;
    }
    if (this.id10) {
      clearTimeout(this.id10);
      this.id10 = null;
    }
  }

}

// Create the Singleton instance.
const DataSourceAPI = new DataSourceApi();
export default DataSourceAPI;
