import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

function Bouncer({children}) {
  const account = useSelector((state) => state.account);
  if (!account || !account.profile || !account.profile.userId) {
    return <Redirect to="/sign-in" />;
  }
  return children;
}

Bouncer.propTypes = {
  children: PropTypes.any
};
export default Bouncer;
