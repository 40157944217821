import React from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  makeStyles,
  ButtonBase
} from '@material-ui/core';
import Page from 'src/components/Page';
import SignInForm from './signInForm';
import Logo from "src/components/Logo";
import {PLATFORM_NAME} from "src/config";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backButton: {
    marginLeft: theme.spacing(2)
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(4, 3, 3, 3)
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.circle,
    padding: theme.spacing(1),
    position: 'absolute',
    boxShadow: theme.shadows[6],
    top: -40,
    right: theme.spacing(4),
    height: 80,
    width: 80
  }
}));

function SignInView() {
  const classes = useStyles();
  const history = useHistory();

  const handleSubmitSuccess = () => {
    // TODO: Push to Instance Picker
    history.push('/apps/dashboard');
  };

  const handleLogoClick = () => {
    history.push('/home');
  };

  return (
    <Page className={classes.root} title="Sign In">
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon} component={ButtonBase} onClick={handleLogoClick}>
              <Logo />
            </Avatar>
            <Typography gutterBottom variant="h2" color="secondary">
              Sign In
            </Typography>
            <Typography variant="subtitle1">
              Sign in to access the {PLATFORM_NAME} web portal.
            </Typography>
            <Box mt={2}>
              <SignInForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
          <CardMedia className={classes.media} image="images/branding/dagda_bkg.png" title="Connected" />
        </Card>
      </Container>
    </Page>
  );
}
export default SignInView;
