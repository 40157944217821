/* eslint-disable import/prefer-default-export */
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';

// Configures the store for managing
// application state objects like members, chat, etc.
export function configureStore(preloadedState = {}) {
  return createStore(rootReducer, preloadedState, applyMiddleware(thunkMiddleware));
}
