import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {PLATFORM_NAME} from "src/config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 40,
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

function FAQS({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" color="textPrimary">
          Frequently asked questions
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Grid container spacing={3} component="dl">
          <Grid item xs={12} md={6}>
            <Typography variant="overline" color="secondary">
              Technical &amp; Licensing
            </Typography>
            <Box mt={6}>
              <dd>
                <Typography variant="h4" color="textPrimary">
                  What devices are supported?
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" color="textSecondary">
                  {PLATFORM_NAME} is available on iOS and iPadOS based devices.
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography variant="h4" color="textPrimary">
                  What about Android?
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" color="textSecondary">
                  We hear you, {PLATFORM_NAME} will be available on Android based devices starting with the next <b>MAJOR</b> release.
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography variant="h4" color="textPrimary">
                 How is {PLATFORM_NAME} licensed?
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" color="textSecondary">
                  {PLATFORM_NAME} licenses are platform based, not user/device based.
                  There is one fee to use the system regardless of the number of users/devices.
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography variant="h4" color="textPrimary">
                  What about upgrades?
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" color="textSecondary">
                  Upgrades are <b>FREE</b> for all valid license holders.
                </Typography>
              </dt>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline" color="secondary">
              Support &amp; Notifications
            </Typography>
            <Box mt={6}>
              <dd>
                <Typography variant="h4" color="textPrimary">
                  Do I have to pay for a support contract?
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" color="textSecondary">
                  No, like our licensing, support is provided for a flat fee per incident.
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography variant="h4" color="textPrimary">
                  Do I have to use the built in call center?
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" color="textSecondary">
                  No, the Network Call Center is a pay per use service. If you don't use it, you don't pay for it.
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography variant="h4" color="textPrimary">
                  Can I send notifications to my customers through {PLATFORM_NAME}?
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" color="textSecondary">
                  Yes, both SMS text messages and voice calls may be used to notify your customers of service issues, appointments, events, etc.
                </Typography>
              </dt>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
