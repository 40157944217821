import jwtDecode from 'jwt-decode';
import axios from "../utils/axios";

class SecurityStorage {

  // Gets the authenticated email
  // address for the last authenticated
  // member account.
  getAuthEmail () {
    const id = localStorage.getItem('auth_identifier');
    if (id) {
      return window.atob(id);
    }
    return '';
  }

  // Sets the last authentication email
  // address for the last authenticated
  // member account.
  setAuthEmail (email) {
    if (email) {
      const enc = window.btoa(email);
      localStorage.setItem('auth_identifier', enc);
    }
  }

  // Gets the last authenticated instance id.
  getAuthInstanceId () {
    const id = localStorage.getItem('auth_instance_id');
    if (id) {
      return window.atob(id);
    }
    return null;
  }

  // Sets the last authenticated instance id.
  setAuthInstanceId (id) {
    if (id) {
      const enc = window.btoa(id + '');
      localStorage.setItem('auth_instance_id', enc);
    }
  }

  // Gets the authentication token for the specified instance id.
  // If no id is provided the default authentication token
  // will be returned from storage.
  getAuthToken (id) {
    return localStorage.getItem(id ? 'auth_token_' + id : 'auth_token');
  }

  // Sets the authentication token for the instance id.
  // If a null token is supplied the token for the Id
  // will be removed. If id is null the default token
  // will be removed.
  setAuthToken (id, token) {
    const key = id ? 'auth_token_' + id : 'auth_token';
    if (token) {
      // Set Token
      localStorage.setItem(key, token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      // Remove Token
      localStorage.removeItem(key);
      delete axios.defaults.headers.common.Authorization;
    }
  }

  // Revokes all authentication tokens stored in local storage.
  revokeAuthTokens () {
    const id = this.getAuthInstanceId();
    if (id) {
      localStorage.removeItem('auth_token_' + id);
      localStorage.removeItem('auth_instance_id');
    }
    localStorage.removeItem('auth_token');
    delete axios.defaults.headers.common.Authorization;
  }

  // Returns true if the auth token exists and is
  // considered to be valid, otherwise false.
  //
  // A auth token is considered to be valid if
  // the 'exp' property is greater than the current
  // timestamp and the 'nbf' property is less than
  // the current timestamp.
  isAuthTokenValid (token) {
    if (token) {
      const jwt = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return jwt.exp > currentTime && currentTime > jwt.nbf;
    }
    return false;
  }

  // Returns true if a auth token exist and is valid.
  //
  // This is a wrapper for the isAuthTokenValid method using the
  // auth token returned from the 'getAuthToken method.
  isAuthenticated () {
    // TODO: REmove after testing.
    return true;
    const token = this.getAuthToken();
    return this.isAuthTokenValid(token);
  }

  // Loads the authentication variables from
  // local storage and validates them.
  // If they are valid the auth token is
  // loaded into axios and a true value is
  // returned, otherwise a false value is returned.
  //
  // Returns: bool
  loadAuthSession () {
    const id = this.getAuthInstanceId();
    const token = this.getAuthToken(id);
    // Set axios authorization header.
    if (this.isAuthTokenValid(token)) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return true
    }
    return false
  }

}

const securityStorage = new SecurityStorage();
export default securityStorage;
