import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './hero';
import Features from './features';
import FAQS from './faqs';
import Footer from './footer';
import { PLATFORM_NAME } from "src/config";

const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title={PLATFORM_NAME}>
      <Hero />
      <Features />
      <FAQS />
      <Footer />
    </Page>
  );
}

export default HomeView;
