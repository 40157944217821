
// Application Information
export const PLATFORM_ID = 12345678;
export const PLATFORM_TYPE = 'web';
export const PLATFORM_NAME = 'IMPACT WOMS';
export const PLATFORM_SHORT_NAME = 'WOMS';
export const PLATFORM_ALIAS = 'Dagda';
export const PLATFORM_LOGO = '/images/branding/logo.svg';
export const PLATFORM_DESCRIPTION = '';
export const PLATFORM_VERSION = '8.2.4';
export const PLATFORM_API_URL = 'api/url';
export const PLATFORM_CURRENCY = {symbol: '$', precision: '.', separator: ','};

// Sets the Command Center name (Dashboard)
// for the platform.
export const COMMAND_CENTER_NAME = 'Dashboard'
export const COMMAND_CENTER_URL = '/apps/dashboard';

// Permission Constants used to build
// Application Navigation.
export const PLATFORM_PERMISSIONS = {
  NONE: 0,
  READ_ONLY: 100,
  READ_WRITE: 110,
  READ_MODIFY:101,
  FULL: 111
};

// Role Constants used to define application roles.
export const PLATFORM_ROLES = {
  240: {id: 240, name: 'Guest'},
  244: {id: 244, name: 'Contractor'},
  246: {id: 246, name: 'Technician'},
  248: {id: 248, name: 'User'},
  250: {id: 250, name: 'Supervisor'},
  252: {id: 252, name: 'Manager'},
  254: {id: 254, name: 'Customer Service'},
  230: {id: 230, name: 'Manager'},
  232: {id: 232, name: 'Read-Only'},
  234: {id: 234, name: 'Administrator'}
};





export const PLATFORM_DATA_URL = PLATFORM_API_URL + '/data/key'
export const PLATFORM_RPT_URL = PLATFORM_API_URL + '/rpt/data';
export const PLATFORM_SEARCH_URL = PLATFORM_API_URL + 'api/search';




// Default Locations.
export const PLATFORM_APPS = {
  profile: {name: '', href: ''},
  account: {name: '', href: ''}
};
