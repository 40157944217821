import authService from '../authService';
import {MEM_KEYS, CMP_KEYS} from './actionKeys';


// Authentication actions are used to set state received from
// calls to the network. The portal service calls should be the only
// state object that need to be stored in application state.
//
// If  a web application using the portal frame work needs to provide
// state management please refer to the 'README' file to ensure that
// the state is properly reduced and brought into the portal state
// management provider.


// This is a wrapper for the authService's 'authWithEmailAndPassword'
// method. The difference is that the action function updates the
// profile state managed by the account reducer. Refer to the 'README' file
// for more details on state management.
export function authWithCredentials (email, password) {
  return async (dispatch) => {
    try {
      // Send a 'RESET' event for the profile.
      // This will tell the state manager (Reducer)
      // to clear the profile.
      dispatch({ type: MEM_KEYS.RESET });

      // Send Auth Request to network for processing
      // and wait on the results. This will send back
      // a profile object. Refer to the 'README' file for
      // more details on the profile object.
      const account = await authService.authWithEmailAndPassword(email, password);

      // Send the profile to state management (authReducer)
      // for processing and storage.
      // The authenticated profile is used extensively to manage
      // application access and routes.
      dispatch({type: MEM_KEYS.FUL_OK, payload: account});

    } catch (error) {
      // Authentication Failed.
      // This will tell the state manager (Reducer)
      // to clear the profile.
      dispatch({ type: MEM_KEYS.FUL_FAIL });
      throw error;
    }
  };
}


// This is a wrapper for the authService's 'authWithMFAToken' method.
// The difference is that the action function updates the profile state managed
// by the instance reducers. Refer to the 'README' file for more details
// on state management.
export function authWithMFAToken (cid, token) {
  return async (dispatch) => {
    try {
      // Send a 'RESET' event for the instance.
      // This will tell the state manager (Reducer)
      // to clear the instance object.
      dispatch({ type: CMP_KEYS.RESET });

      // Send the MFA authentication request to network for
      // for processing.
      const instance = await authService.authWithMFAToken(cid, token);

      // Send the instance object to the state manager for processing.
      dispatch({type: CMP_KEYS.FUL_OK, payload: instance});

    } catch (error) {
      // Authentication Failed for the instance.
      // This will tell the state manager (Reducer)
      // to clear the instance object.
      dispatch({ type: CMP_KEYS.FUL_FAIL });
      throw error;
    }
  };
}


// This is a wrapper for the authService's 'auth' method.
// The difference is that the action function updates the profile state managed
// by the account/instance reducers. Refer to the 'README' file for more details
// on state management. If a valid JWT has not be set in the authorization header
// a null value is returned.
export function authWithJsonWebToken () {
  return async (dispatch) => {
    try {
      // Send a 'RESET' event for the account/instance.
      // This will tell the state manager (Reducer)
      // to clear the account/instance objects.
      dispatch({ type: MEM_KEYS.RESET });
      dispatch({ type: CMP_KEYS.RESET });

      // Send the JWT authentication request to network for
      // for processing.
      const {account, instance} = await authService.authWithJsonWebToken();

      // Send the account instance object to the state manager for processing.
      dispatch({type: MEM_KEYS.FUL_OK, payload: account});
      dispatch({type: CMP_KEYS.FUL_OK, payload: instance});

    } catch (error) {
      // Authentication Failed for the instance.
      // This will tell the state manager (Reducer)
      // to clear the account/instance objects.
      dispatch({type: MEM_KEYS.FUL_FAIL});
      dispatch({ type: CMP_KEYS.FUL_FAIL });
      throw error;
    }
  };
}


// This is a wrapper for the authService's 'logOffNetwork' method.
// The difference is that the action function updates the profile state managed
// by the profileReducer. Refer to the 'README' file for more details
// on state management.
export function logOffNetwork () {
  return async (dispatch) => {
    try {
      // Try to logoff the authenticated member account.
      //await authService.logOffNetwork();

      // Regardless of the outcome remove the
      // account and instance from the state manager.
      dispatch({ type: MEM_KEYS.RESET });
      //dispatch({ type: CMP_KEYS.RESET });

    } catch (error) {
      // LogOff Failed for the Member.
      // This will tell the state manager (authReducer)
      // to clear the profile/instance object.
      dispatch({ type: MEM_KEYS.RESET });
      //dispatch({ type: CMP_KEYS.RESET });
      throw error;
    }
  };
}


// This is a wrapper for the authService's 'logOffInstance' method.
// The difference is that the action function updates the profile state managed
// by the profileReducer. Refer to the 'README' file for more details
// on state management.
export function logOffInstance () {
  return async (dispatch) => {
    try {
      // Regardless of the outcome remove the
      // instance from the state manager.
      dispatch({ type: CMP_KEYS.RESET });

      // LogOff the Instance.
      // This will always succeed.
      authService.logOffInstance();

    } catch (error) {
      // Authentication Failed for the instance.
      // This will tell the state manager (authReducer)
      // to clear the instance object.
      dispatch({ type: CMP_KEYS.RESET });
      throw error;
    }
  };
}
