import React, {useState, useRef} from 'react';
import {capitalCase} from 'change-case';
import {
  Box,
  Button,
  FormControlLabel,
  Popover,
  Switch,
  TextField,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {Settings as SettingsIcon} from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import {THEMES} from 'src/constants';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

function Settings() {
  const classes = useStyles();
  const ref = useRef(null);
  const {settings, saveSettings} = useSettings();

  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const handleSettingsOpen = () => {
    setOpen(true);
  };

  const handleSettingsClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Interface Preferences">
        <IconButton color="inherit" onClick={handleSettingsOpen} ref={ref}>
          <SvgIcon fontSize="small">
            <SettingsIcon/>
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{paper: classes.popover}}
        anchorEl={ref.current}
        onClose={handleSettingsClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          Interface Preferences
        </Typography>
        <Box mt={2} px={1}>
          <FormControlLabel
            control={(
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)} />
            )}
            label="Responsive font sizes"
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{native: true}}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option key={theme} value={theme}>
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleSave}>
            Save Preferences
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
