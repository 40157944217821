import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Auth from './security/Auth';
import CookieDialog from '../components/CookieDialog';
import ScrollRebound from '../components/ScrollRebound';
import useSettings from '../hooks/useSettings';
import { createTheme } from '../theme';
import Routes from './Routes';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins] });
const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

// Load the application into memory and creates
// All the necessary routes. Although all routes
// will be created not all routes will be available
// to all users. This is defined by the permissions
// set for the user by the company instance administrator.
function App() {
  useStyles();
    const { settings } = useSettings();
    return (
      <ThemeProvider theme={createTheme(settings)}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={4}>
              <Router history={history}>
                <Auth>
                  <ScrollRebound />
                  <CookieDialog />
                  <Routes />
                </Auth>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    );
}
export default App;
