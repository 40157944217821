// Account Keys
export const MEM_KEYS = {
  RESET: '@mem/reset-all',

  PRO_OK: '@mem/pro-ok',
  ADD_OK: '@mem/add-ok',
  CNT_0K: '@mem/cnt-ok',
  RRF_OK: '@mem/prf-ok',
  ACC_OK: '@mem/acc-ok',
  FUL_OK: '@mem/full-ok',

  PRO_FAIL: '@mem/pro-fail',
  ADD_FAIL: '@mem/add-fail',
  CNT_FAIL: '@mem/cnt-fail',
  RRF_FAIL: '@mem/prf-fail',
  ACC_FAIL: '@mem/acc-fail',
  FUL_FAIL: '@mem/full-fail',
};

// Instance Keys
export const CMP_KEYS = {
  RESET: '@cmp/reset-all',

  PRO_OK: '@cmp/pro-ok',
  ADD_OK: '@cmp/add-ok',
  CNT_0K: '@cmp/cnt-ok',
  RRF_OK: '@cmp/prf-ok',
  ACC_OK: '@cmp/acc-ok',
  FUL_OK: '@cmp/full-ok',

  PRO_FAIL: '@cmp/pro-fail',
  ADD_FAIL: '@cmp/add-fail',
  CNT_FAIL: '@cmp/cnt-fail',
  RRF_FAIL: '@cmp/prf-fail',
  ACC_FAIL: '@cmp/acc-fail',
  FUL_FAIL: '@cmp/full-fail',
};
