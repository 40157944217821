import React, {
  useRef,
  useState
} from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  fade
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import {logOffInstance, logOffNetwork} from 'src/services/actions/authActions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 36,
    width: 36,
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: fade(theme.palette.common.black, 0.25),
  },
  popover: {
    width: 200
  },
  title: {
    maxWidth: 180,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: theme.typography.fontWeightMedium
  },
  subTitle: {
    marginTop: -2,
    color: fade(theme.palette.common.white, 0.55)
  }
}));

function Account() {

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { profile, access } = useSelector((state) => state.account);

  const privileges = useSelector((state) => state.instance.privileges);
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitchClick = async () => {
    // TODO: Set the proper redirect.
    setOpen(false);
    try {
      await dispatch(logOffInstance());
      history.push('/command-center');
    } catch (error) {
      enqueueSnackbar('Unable to switch accounts.', {variant: 'warning'});
    }
  };

  const handleLogOffNetwork = async () => {
    // TODO: Set the proper redirect.
    setOpen(false);
    try {
      history.push('/sign-in');
      await dispatch(logOffNetwork());
    } catch (error) {
      enqueueSnackbar('Unable to logout', { variant: 'warning'});
    }
  };

  const handleAccountClick = async () => {
    setOpen(false);
    // TODO: Set Account Directory
    history.push('');
  };

  const handleProfileClick = async () => {
    setOpen(false);
    // TODO: Set Account Profile Directory
    history.push('');
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar alt="Member" className={classes.avatar} src={profile.avatar}>
          {getInitials(`${profile.firstName} ${profile.lastName}`)}
        </Avatar>
        <Hidden smDown>
          <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="center">
            <Typography variant="h6" color="inherit" className={classes.title}>
              {`${profile.firstName} ${profile.lastName}`}
            </Typography>
            {privileges && privileges.title && (
              <Typography variant="caption" color="inherit" className={clsx(classes.title, classes.subTitle)}>
                {privileges.title}
              </Typography>
            )}
          </Box>
        </Hidden>
      </Box>
      <Menu onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
      >
        {/*<MenuItem onClick={handleAccountClick}>*/}
        {/*  My Account*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={handleProfileClick}>*/}
        {/*  My Profile*/}
        {/*</MenuItem>*/}
        {access && access.length > 0 && (
          <MenuItem onClick={handleSwitchClick}>
            Switch Instance
          </MenuItem>
        )}
        <MenuItem onClick={handleLogOffNetwork}>
          Logoff
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;

// TODO: Change Links to the profile/account.
