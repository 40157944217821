/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import DashboardLayout from 'src/core/layouts/dashboard';
import MainLayout from './layouts/main';
import SignInView from '../apps/auth/signin';
import DocsLayout from "./layouts/docs";

import TermsOfServiceView from "src/views/legal/terms";
import PrivacyPolicyView from "src/views/legal/privacy";
import HomeView from '../views/main/home';

// TODO: Add Bouncer to '/apps route.
import Bouncer from './security/Bouncer';

// Defines the Routes served
// by the application.
const routes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/sign-in',
    component: SignInView
  },
  {
    path: '/apps',
    layout: DashboardLayout,
    guard: Bouncer,
    routes: [
      {
        exact: true,
        path: '/apps/dashboard',
        component: lazy(() => import('src/apps/dashboard'))
      },
      {
        exact: true,
        path: '/apps/assets',
        component: lazy(() => import('src/apps/assets'))
      },
      {
        exact: true,
        path: '/apps/assets/:assetId',
        component: lazy(() => import('src/apps/assets/details'))
      },
      {
        exact: true,
        path: '/apps/commodity',
        component: lazy(() => import('src/apps/commodity/summary'))
      },
      {
        exact: true,
        path: '/apps/commodity/details/:commodityId',
        component: lazy(() => import('src/apps/commodity/details'))
      },
      {
        exact: true,
        path: '/apps/warehouse',
        component: lazy(() => import('src/apps/warehouse/summary'))
      },
      {
        exact: true,
        path: '/apps/warehouse/details/:warehouseId',
        component: lazy(() => import('src/apps/warehouse/details'))
      },
      {
        exact: true,
        path: '/apps/warehouse/inventory/:warehouseId/:inventoryId',
        component: lazy(() => import('src/apps/warehouse/inventory'))
      },
      {
        exact: true,
        path: '/apps/maps/:userId',
        component: lazy(() => import('src/apps/maps'))
      },
      {
        exact: true,
        path: '/apps/orders',
        component: lazy(() => import('src/apps/orders/review'))
      },
      {
        exact: true,
        path: '/apps/orders/details/:locationId',
        component: lazy(() => import('src/apps/orders/details'))
      },
      {
        exact: true,
        path: '/apps/orders/assign',
        component: lazy(() => import('src/apps/orders/assign'))
      },
      {
        exact: true,
        path: '/apps/techs',
        component: lazy(() => import('src/apps/techs'))
      },
      {
        exact: true,
        path: '/apps/techs/details/:userId',
        component: lazy(() => import('src/apps/techs/details'))
      },
      {
        exact: true,
        path: '/apps/reports/production',
        component: lazy(() => import('src/apps/reports/production'))
      },
      {
        exact: true,
        path: '/apps/reports/production/tech',
        component: lazy(() => import('src/apps/reports/tech'))
      },
      {
        exact: true,
        path: '/apps/reports/saturation',
        component: lazy(() => import('src/apps/reports/saturation'))
      },
      {
        exact: true,
        path: '/apps/reports/tech/stats/all',
        component: lazy(() => import('src/apps/reports/stats/all'))
      },
      {
        exact: true,
        path: '/apps/reports/tech/stats/date',
        component: lazy(() => import('src/apps/reports/stats/date'))
      },
      {
        exact: true,
        path: '/apps/test',
        component: lazy(() => import('src/views/test'))
      },
      {
        exact: true,
        path: '/apps/skips',
        component: lazy(() => import('src/apps/skips'))
      },
      {
        exact: true,
        path: '/apps/skips/details/:locationId',
        component: lazy(() => import('src/apps/skips/details'))
      },
    ]
  },
  {
    path: '/legal',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/legal/terms-conditions',
        component: TermsOfServiceView
      },
      {
        exact: true,
        path: '/legal/privacy-policy',
        component: PrivacyPolicyView
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        component: () => <Redirect to="/home" />
      }
    ]
  }
];

const processRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route key={i} path={route.path} exact={route.exact} render={(props) => (
            <Guard>
              <Layout>
                {route.routes ? processRoutes(route.routes) : <Component {...props} />}
              </Layout>
            </Guard>
          )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return processRoutes(routes);
}
export default Routes;
