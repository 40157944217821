/* eslint-disable no-param-reassign */
import produce from 'immer';
import {MEM_KEYS} from 'src/services/actions/actionKeys';

const initialState = {
  profile: {},
  addresses: [],
  contacts: [],
  preferences: [],
  access: []
};

// Manages authenticated member and company profiles.
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEM_KEYS.RESET: {
      return produce(state, (draft) => {
        draft.profile = {};
        draft.preferences = [];
        draft.addresses = [];
        draft.contacts = [];
        draft.access = [];
      });
    }
    case MEM_KEYS.FUL_FAIL: {
      return produce(state, (draft) => {
        draft.profile = {};
        draft.preferences = [];
        draft.addresses = [];
        draft.contacts = [];
        draft.access = [];
      });
    }
    case MEM_KEYS.FUL_OK: {
      //const {profile, addresses, contacts, preferences, access} = action.payload;
      return produce(state, (draft) => {
        draft.profile = action.payload;
        draft.preferences = null;
        draft.access = null;
        draft.addresses = null;
        draft.contacts = null;
      });
    }
    default: {
      return state;
    }
  }
}
export default accountReducer;
