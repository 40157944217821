/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {useLocation, matchPath} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  fade
} from '@material-ui/core';
import {
  FaWarehouse as WarehouseIcon,
  //FaRegObjectGroup as CommodityIcon,
  FaChartPie as ChartIcon
} from 'react-icons/fa';
import {
  CgToolbox as OrdersIcon,
  CgQr as AssetIcon
} from 'react-icons/cg';
import {
  FiBarChart2 as DashboardIcon,
  FiCompass as MapIcon,
  FiUsers as TechniciansIcon
} from 'react-icons/fi';
import {
  GiBeard as GodModeIcon
} from "react-icons/gi/index";
import NavItem from './NavItem';
import getInitials from 'src/utils/getInitials';
import Branding from 'src/components/Branding';


// TODO: Update navConfig to match member access.
const navConfig = [
  {
    subheader: '',
    items:[
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/apps/dashboard',
        exact: false
      },
      // {
      //   title: 'Assets',
      //   icon: AssetIcon,
      //   href: '/apps/assets',
      //   exact: false
      // },
      {
        title: 'Warehouse',
        icon: WarehouseIcon,
        href: '/apps/warehouse',
        exact: false
      },
      // {
      //   title: 'Commodity',
      //   icon: CommodityIcon,
      //   href: '/apps/commodity',
      //   exact: false
      // },
      {
        title: 'God Mode',
        icon: GodModeIcon,
        href: '/apps/maps/-1',
        exact: false
      },
      {
        title: 'Technicians',
        icon: TechniciansIcon,
        href: '/apps/techs',
        exact: false
      },
      {
        title: 'Work Orders',
        icon: OrdersIcon,
        href: '/apps/orders',
        exact: false,
        items: [
          {
            title: 'Orders',
            href: '/apps/orders',
            exact: true
          },
          {
            title: 'Skips',
            href: '/apps/skips',
            exact: true
          },
          // {
          //   title: 'Assigned',
          //   href: '/apps/orders/assign',
          //   exact: true
          // }
        ]
      },
      {
        title: 'Reports',
        icon: ChartIcon,
        //href: '/apps/orders/reports',
        exact: false,
        items: [
          {
            title: 'Production Summary',
            href: '/apps/reports/production',
            exact: true
          },
          {
            title: 'Production By Tech',
            href: '/apps/reports/production/tech',
            exact: true
          },
          {
            title: 'Saturation Summary',
            href: '/apps/reports/saturation',
            exact: true
          },
          {
            title: 'Tech Stats Summary',
            href: '/apps/reports/tech/stats/all',
            exact: true
          },
          {
            title: 'Tech Stats Date-Range',
            href: '/apps/reports/tech/stats/date',
            exact: true
          }
        ]
      }

    ]
  }
];

// TODO: Set Navigation Links for Branding

function renderNavItems({items, ...rest}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({acc, item, ...rest}),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({acc, pathname, item, depth = 0}) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {path: item.href, exact: false});
    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} info={item.info} exact={true} open={Boolean(open)} title={item.title}>
        {renderNavItems({depth: depth + 1, pathname, items: item.items})}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem depth={depth} href={item.href} icon={item.icon} exact={item.exact} key={key} info={item.info} title={item.title}/>
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    color: theme.palette.common.white,
    backgroundColor: fade(theme.palette.common.black, 0.25),
  }
}));

function NavBar({openMobile, onMobileClose}) {
  const classes = useStyles();
  const location = useLocation();
  const member = useSelector((state) => state.account.profile);
  const privileges = useSelector((state) => state.instance.privileges);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{suppressScrollX: true}}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="flex-start">
            <Branding />
          </Box>
          <Divider/>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/console/account">
              <Avatar alt="Member" className={classes.avatar} src={member.avatar}>
                {getInitials(`${member.firstName} ${member.lastName}`)}
              </Avatar>
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/console/account" variant="h5" color="textPrimary" underline="none">
              {`${member.firstName} ${member.lastName}`}
            </Link>
            <Typography variant="h6" color="textSecondary">
              {privileges ? privileges.title : 'Member'}
            </Typography>
          </Box>
        </Box>
        <Divider/>
        <Box p={2}>
          {navConfig.map((config) => (
            <List key={config.subheader}
                  subheader={(
                    <ListSubheader disableGutters disableSticky>
                      {config.subheader}
                    </ListSubheader>
                  )}>
              {renderNavItems({items: config.items, pathname: location.pathname})}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{paper: classes.mobileDrawer}} onClose={onMobileClose} open={openMobile}
                variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{paper: classes.desktopDrawer}} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};
export default NavBar;

// TODO: Redo Help Section.
// TODO: Update link Urls.
