import appleAuthService from 'src/services/appleAuthService';

// Manages Apple Maps API authentication services.
class AppleMapsServiceApi {

  initMaps () {
    window.mapkit.init({
      authorizationCallback: async function(done) {
        try {
          const token = await appleAuthService.generateAppleMapsToken();
          done(token);
        } catch(error) {
          done('');
        }
      }
    });
  }

  // TODO: Add ability to destroy all maps.

}

// Create the Singleton instance.
const AppleMapsServiceAPI = new AppleMapsServiceApi();
export default AppleMapsServiceAPI;
