import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';

import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { configureStore } from './store';
import { SettingsProvider } from './settings/context';
import { loadSettings } from './storage/settings';
import App from './core/App';

// MARK: RunLoop Services
import DataSourceAPI from './api/DataSourceAPI';

// MARK: Apple Maps Services
import AppleMapsServiceAPI from './api/AppleMapsServiceAPI';




// TODO: Remove Mock for Production Environment
//import './mock';

enableES5();

const store = configureStore();
const settings = loadSettings();

// MARK: Start Services.
//DataSourceAPI.start();

// MARK: Init Apple Maps.
AppleMapsServiceAPI.initMaps();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);
