import React from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { Container, makeStyles } from '@material-ui/core';
import TopBar from './topBar';
import components from './mdx';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'auto'
  },
  content: {
    paddingBottom: 120
  }
}));

function DocsLayout({ children }) {
  const classes = useStyles();

  return (
    <>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <Container
            maxWidth="md"
            className={classes.content}
          >
            <MDXProvider components={components}>
              {children}
            </MDXProvider>
          </Container>
        </div>
      </div>
    </>
  );
}

DocsLayout.propTypes = {
  children: PropTypes.any
};

export default DocsLayout;
