import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import securityStorage from 'src/storage/security';
import {authWithJsonWebToken} from 'src/services/actions/authActions';

// TODO: Update the auth services.
function Auth({children}) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
      const verifyCredentials = async () => {
        // securityStorage.loadAuthSession();
        // if (securityStorage.isAuthenticated()) {
        //   await dispatch(authWithJsonWebToken())
        // }
      };
      verifyCredentials().finally(() => setLoading(false));
    }, [dispatch]
  );

  if (isLoading) {
    return <SplashScreen/>;
  }
  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};
export default Auth;
